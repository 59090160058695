import React from 'react'
import { Link } from 'gatsby'
import logo from '../assets/images/flotherm/ft-logo-white-full-trim.svg'

const Footer = (props) => (
  <footer id="footer">
    <ul className="copyright">
      <li>
        <img src={logo} alt="Flotherm TCD" />
      </li>
      <li>&copy; Flotherm. All rights reserved.</li>
      <li>
        <Link to="/privacy-policy">Privacy Policy</Link>
      </li>
      <li>
        <Link to="/terms-and-conditions">Terms of Service</Link>
      </li>
      <li>
        {/* <a href="/" className="icon brands fa-linkedin-in">
          <span className="label">LinkedIn</span>
        </a> */}
        <a
          href="mailto:info@flothermtcd.com"
          className="icon solid fa-envelope"
        >
          <span className="label">Email</span>
        </a>
        <a
          href="https://bitbucket.org/bradliang/flotherm-web/src/master/"
          className="icon brands fa-bitbucket"
        >
          <span className="label">bitbucket</span>
        </a>
      </li>
    </ul>
  </footer>
)

export default Footer
